import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const intitalState = {
    results: ''
};

const loadScreenshotData = (state, action) => {
    return updateObject(state, {
        results: action.results
    });
}

const clearScreenshotData = (state) => {
    return updateObject(state, {
        results: ''
    });
}

const reducer = (state = intitalState, action) => {
    switch (action.type) {
        case actionTypes.Screenshot_Data: return loadScreenshotData(state, action);
        case actionTypes.Clear_Screenshot_Data: return clearScreenshotData(state);
        default: return state;
    }
}

export default reducer;